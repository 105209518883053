/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/
import dashboard from './dashboard'
// import biglietteria from './biglietteria'
// import anagrafica from './anagrafica'
// import produzioni from './produzioni'
// import contratti from './contratti'
// import contabilita from './contabilita'
// import destinatari from './destinatari'
// import scadenze from './scadenze'
// import sviluppo from './sviluppo'

import configurazioni from './configurazioni'
import vendite from './vendite'
import tabelle from './tabelle'

// Array of sections
export default [...dashboard, ... configurazioni, ... tabelle, ... vendite]
//export default [...dashboard, ... configurazioni, ... vendite, ...biglietteria, ...anagrafica, ...produzioni, ...contratti, ...contabilita, ...destinatari, ...scadenze, ...sviluppo]
// versione ridotta
//export default [...dashboard, ...biglietteria, ...anagrafica, ...produzioni, ...contratti, ...contabilita] //, ...destinatari, ...scadenze, ...sviluppo]
// versione mini debug
//export default [...dashboard]