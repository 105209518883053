export default [
  {
    header: 'Tabelle',
    resource: 'tabelle',
    action: 'read',
  },
  {
    title: 'Tab. Appl. Compensi',
    icon: 'UserIcon',
    route: 'tabelleApplicazioneCompensi',
    resource: 'tabelle',
    action: 'read',
  },
  {
    title: 'Tab. Comm. e Punti',
    icon: 'UserIcon',
    route: 'tabelleCommissionPunti',
    resource: 'tabelle',
    action: 'read',
  },
  {
    title: 'Tab. Compensi',
    icon: 'UserIcon',
    route: 'tabelleCompensi',
    resource: 'tabelle',
    action: 'read',
  },
  {
    title: 'Tab. Gara Service',
    icon: 'UserIcon',
    route: 'tabelleGaraService',
    resource: 'tabelle',
    action: 'read',
  },
  {
    title: 'Tab. Imp. Att. Banco',
    icon: 'UserIcon',
    route: 'tabelleImportiBanco',
    resource: 'tabelle',
    action: 'read',
  },
  {
    title: 'Tab. Premi F100',
    icon: 'UserIcon',
    route: 'tabellePremiF100',
    resource: 'tabelle',
    action: 'read',
  },
]
