export default [
  {
    header: 'Configurazioni',
    resource: 'configurazioni',
    action: 'read',
  },
  {
    title: 'Negozi',
    icon: 'UserIcon',
    route: 'negozi',
    resource: 'configurazioni',
    action: 'read',
  },
  {
    title: 'Presidi',
    icon: 'UserIcon',
    route: 'presidi',
    resource: 'configurazioni',
    action: 'read',
  },
  {
    title: 'Venditori',
    icon: 'UserIcon',
    route: 'venditori',
    resource: 'configurazioni',
    action: 'read',
  },
  {
    title: 'Agenti Esterni',
    icon: 'UserIcon',
    route: 'agenti',
    resource: 'configurazioni',
    action: 'read',
  },
  {
    title: 'Tip. Attività Banco',
    icon: 'UserIcon',
    route: 'tipologieAttivitaBanco',
    resource: 'configurazioni',
    action: 'read',
  },
  {
    title: 'Tip. Vendita',
    icon: 'UserIcon',
    route: 'tipologieVendita',
    resource: 'configurazioni',
    action: 'read',
  },
  {
    title: 'Scenari Vendita',
    icon: 'UserIcon',
    route: 'scenariVendita',
    resource: 'configurazioni',
    action: 'read',
  },
]
