export default [
  {
    header: 'Vendite e Banco',
    resource: 'vendite',
    action: 'read',
  },
  {
    title: 'Vendite',
    icon: 'UserIcon',
    route: 'vendite',
    resource: 'vendite',
    action: 'read',
  },
  {
    title: 'Attività Banco',
    icon: 'UserIcon',
    route: 'attivitaBanco',
    resource: 'vendite',
    action: 'read',
  },
]
